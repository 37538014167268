@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.work-header {
	display: flex;
}

.work-image {
	width: 40px;
	outline: 3px solid white;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 60px;
}

.work-duration {
	position: absolute;
	width: 320px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.work-description {
	font-size: 14px;
	padding-top: 10px;
	padding-left: 60px;
}